import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "fast-check-container"
};
const _hoisted_2 = {
  class: "custom-date-container"
};
const _hoisted_3 = {
  class: "range-btn-container"
};
const _hoisted_4 = {
  style: {
    "width": "47%"
  }
};
const _hoisted_5 = {
  style: {
    "width": "47%"
  }
};
import { computed, ref } from 'vue';
import { showToast } from 'vant';
import { formatYMD, get30Day, get7Day, getYesterday, getToday, defaultOpts, isValid } from '@/components/DateRangePicker/util';
export default {
  __name: 'DateRangePicker',
  props: {
    show: {
      type: Boolean,
      default: () => false
    },
    fastCheckShow: {
      type: Boolean,
      default: () => true
    },
    zIndex: {
      type: [Number, String],
      default: () => 2000
    },
    initValue: {
      type: Array,
      default: () => []
    },
    confirm: {
      type: [null, Function],
      default: () => null
    }
  },
  emits: ['update:show', 'confirm'],
  setup(__props, {
    emit: __emit
  }) {
    const $props = __props;
    const $emit = __emit;
    const selectStartDate = ref(null);
    const selectEndDate = ref(null);
    const minDate = ref(defaultOpts().minDate);
    const maxDate = ref(defaultOpts().maxDate);
    const fastList = ref([{
      name: '近30天',
      getDays: get30Day
    }, {
      name: '近7天',
      getDays: get7Day
    }, {
      name: '昨日',
      getDays: getYesterday
    }, {
      name: '今日',
      getDays: getToday
    }]);
    const showDatePicker = ref(false);
    const datePickerCurrentDate = ref([]);
    const pickShow = computed({
      get() {
        const {
          show,
          initValue
        } = $props;
        if (show) {
          if (initValue.length === 2) {
            const [start, end] = initValue;
            selectStartDate.value = formatYMD(start);
            selectEndDate.value = formatYMD(end);
          } else {
            const [today] = getToday();
            initSelect(today, today);
          }
        }
        return show;
      },
      set(val) {
        $emit('update:show', val);
      }
    });
    const fastSelectIndex = computed(() => {
      return getFastItemIndex(selectStartDate.value, selectEndDate.value);
    });
    function getFastItemIndex(selectStartDate, selectEndDate) {
      const _fastList = fastList.value;
      let fastSelectIndex = -1;
      for (let index = 0; index < _fastList.length; index++) {
        const getDays = _fastList[index].getDays;
        const [start, end] = getDays();
        if (selectStartDate === start && selectEndDate === end) {
          fastSelectIndex = index;
          break;
        }
      }
      return fastSelectIndex;
    }
    function toSelectFastDate(index) {
      const {
        getDays
      } = fastList.value[index];
      const [startDate, endDate] = getDays();
      selectStartDate.value = startDate;
      selectEndDate.value = endDate;
      confirm();
    }
    function fastSelectColor(thisIndex, selectIndex) {
      const isSelect = thisIndex === selectIndex;
      return {
        color: isSelect ? '#eaeeff' : '#f7f7f7',
        textColor: isSelect ? '#15266C' : '#666'
      };
    }
    let dateKey = '';
    function toSelectDate(key) {
      dateKey = key;
      if (dateKey === 'start') datePickerCurrentDate.value = selectStartDate.value.split('-');
      if (dateKey === 'end') datePickerCurrentDate.value = selectEndDate.value.split('-');
      showDatePicker.value = true;
    }
    function onDateConfirm({
      selectedValues
    }) {
      const dateVal = selectedValues.join('-');
      if (dateKey === 'start') selectStartDate.value = dateVal;
      if (dateKey === 'end') selectEndDate.value = dateVal;
      showDatePicker.value = false;
    }
    function initSelect(start = null, end = null) {
      selectStartDate.value = start;
      selectEndDate.value = end;
      minDate.value = defaultOpts().minDate;
      maxDate.value = defaultOpts().maxDate;
    }
    function reset() {
      initSelect();
      confirm();
    }
    function confirm() {
      if (!isValid(selectStartDate.value, selectEndDate.value)) return showToast({
        position: 'bottom',
        message: '开始日期必须小于结束日期'
      });
      let data = [];
      if (selectStartDate.value && selectEndDate.value) data = [selectStartDate.value, selectEndDate.value];
      const fast = fastList.value[fastSelectIndex.value];
      const fastText = fast ? fast.name || '' : '';
      $emit('confirm', data, fastText);
      typeof $props.confirm === 'function' && $props.confirm(data, fastText);
      pickShow.value = false;
    }
    return (_ctx, _cache) => {
      const _component_van_tag = _resolveComponent("van-tag");
      const _component_van_button = _resolveComponent("van-button");
      const _component_van_popup = _resolveComponent("van-popup");
      const _component_van_date_picker = _resolveComponent("van-date-picker");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_popup, {
        show: pickShow.value,
        "onUpdate:show": _cache[3] || (_cache[3] = $event => pickShow.value = $event),
        class: "date-range-picker-popup",
        position: "bottom",
        closeable: "",
        round: "",
        duration: "0.2",
        "z-index": __props.zIndex
      }, {
        default: _withCtx(() => [_cache[10] || (_cache[10] = _createElementVNode("div", {
          class: "date-range-picker-title"
        }, "请选择日期", -1)), __props.fastCheckShow ? (_openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(fastList.value, (item, index) => {
          return _openBlock(), _createBlock(_component_van_tag, {
            key: item.name,
            size: "large",
            color: fastSelectColor(index, fastSelectIndex.value).color,
            "text-color": fastSelectColor(index, fastSelectIndex.value).textColor,
            onClick: $event => toSelectFastDate(index)
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(item.name), 1)]),
            _: 2
          }, 1032, ["color", "text-color", "onClick"]);
        }), 128))])) : _createCommentVNode("", true), _cache[11] || (_cache[11] = _createElementVNode("div", {
          class: "def-data-title"
        }, " 时间区间 ", -1)), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
          class: "custom-date-wrap",
          onClick: _cache[0] || (_cache[0] = $event => toSelectDate('start'))
        }, _toDisplayString(selectStartDate.value || '开始时间'), 1), _cache[7] || (_cache[7] = _createElementVNode("div", {
          style: {
            "color": "#999"
          }
        }, " ~ ", -1)), _createElementVNode("div", {
          class: "custom-date-wrap",
          onClick: _cache[1] || (_cache[1] = $event => toSelectDate('end'))
        }, _toDisplayString(selectEndDate.value || '结束时间'), 1)]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_van_button, {
          type: "default",
          block: "",
          plain: "",
          onClick: _cache[2] || (_cache[2] = $event => reset(null))
        }, {
          default: _withCtx(() => _cache[8] || (_cache[8] = [_createTextVNode("重置")])),
          _: 1
        })]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_van_button, {
          type: "primary",
          block: "",
          onClick: confirm
        }, {
          default: _withCtx(() => _cache[9] || (_cache[9] = [_createTextVNode("确认")])),
          _: 1
        })])])]),
        _: 1
      }, 8, ["show", "z-index"]), _createVNode(_component_van_popup, {
        show: showDatePicker.value,
        "onUpdate:show": _cache[6] || (_cache[6] = $event => showDatePicker.value = $event),
        round: "",
        position: "bottom",
        "z-index": __props.zIndex
      }, {
        default: _withCtx(() => [_createVNode(_component_van_date_picker, {
          modelValue: datePickerCurrentDate.value,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => datePickerCurrentDate.value = $event),
          "min-date": minDate.value,
          "max-date": maxDate.value,
          formatter: (type, option) => {
            if (type === 'year') option.text += '年';
            if (type === 'month') option.text += '月';
            if (type === 'day') option.text += '日';
            return option;
          },
          onConfirm: onDateConfirm,
          onCancel: _cache[5] || (_cache[5] = $event => showDatePicker.value = false)
        }, null, 8, ["modelValue", "min-date", "max-date", "formatter"])]),
        _: 1
      }, 8, ["show", "z-index"])], 64);
    };
  }
};