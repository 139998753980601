import "core-js/modules/es.array.push.js";
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createBlock as _createBlock, Teleport as _Teleport } from "vue";
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  key: 1
};
const _hoisted_3 = {
  class: "bank-card-select-name"
};
const _hoisted_4 = {
  class: "bank-card-select-sub-info"
};
const _hoisted_5 = {
  class: "fast-cards-pay-bank-list"
};
const _hoisted_6 = {
  style: {
    "padding": "1em"
  }
};
import { computed, reactive, ref, watchEffect } from 'vue';
import { showConfirmDialog, showToast } from 'vant';
import { getBindBankCards, quickBuckleUnbind } from '@/api/quickPay';
import { onBeforeRouteLeave, useRouter } from 'vue-router';
import { banCardNoDesensitization, phoneDesensitization } from '@/util';
export default {
  __name: 'quickPayBankSelect',
  props: {
    modelValue: {
      type: [Number, String, null],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    supplierId: {
      type: [Number, String, null],
      default: null
    }
  },
  emits: ['update:modelValue', 'update:loading'],
  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    const $props = __props;
    const $emit = __emit;
    const $router = useRouter();
    const bankCardList = ref([]);
    const listLoading = ref(false);
    const radioPop = reactive({
      show: false,
      modelValue: null
    });
    const loadingValue = computed({
      get() {
        return $props.loading;
      },
      set(val) {
        $emit('update:loading', val);
      }
    });
    const value = computed({
      get() {
        return $props.modelValue;
      },
      set(val) {
        $emit('update:modelValue', val);
      }
    });
    const selectBankCardInfo = computed(() => {
      const [select] = bankCardList.value.filter(e => e.id === value.value);
      return select || {
        bankName: '',
        bankCard: '',
        cardMp: ''
      };
    });
    const disableForm = computed(() => loadingValue.value || listLoading.value);
    function init(supplierId) {
      listLoading.value = true;
      loadingValue.value = true;
      return getBindBankCards({
        supplierId
      }).then(res => {
        const dataList = Array.isArray(res) ? res : [];
        bankCardList.value = dataList;
        if (!dataList.length) {
          showConfirmDialog({
            message: `您还未绑定银行卡，是否前去绑定？`,
            confirmButtonText: '去绑定'
          }).then(toAddBankCard).catch(() => {});
        } else {
          const [last] = dataList.filter(e => e.id === value.value);
          value.value = (last === null || last === void 0 ? void 0 : last.id) || dataList[0].id;
        }
        listLoading.value = false;
        loadingValue.value = false;
      });
    }
    function toAddBankCard() {
      $router.push(`/quickPayBindBankCard?supplierId=${$props.supplierId}`);
    }
    function toSelectBank() {
      if (!bankCardList.value.length) return toAddBankCard();
      radioPop.modelValue = value.value;
      radioPop.show = true;
    }
    function renderCardInfoText(card) {
      return `卡号: ${banCardNoDesensitization(card.bankCard)} 电话: ${phoneDesensitization(card.cardMp)}`;
    }
    function del(id) {
      showConfirmDialog({
        title: '提示',
        message: '是否解除绑定？',
        confirmButtonText: '解除绑定'
      }).then(() => {
        quickBuckleUnbind({
          id
        }).then(res => {
          if (res) {
            init($props.supplierId);
            if (id == value.value) value.value = null;
            showToast('解除绑定成功');
          }
        });
      }).catch(() => {});
    }
    onBeforeRouteLeave(() => {
      radioPop.show = false;
    });
    watchEffect(() => {
      const supplierId = $props.supplierId;
      init(supplierId);
    });
    __expose({
      init
    });
    return (_ctx, _cache) => {
      const _component_van_cell = _resolveComponent("van-cell");
      const _component_van_button = _resolveComponent("van-button");
      const _component_van_radio = _resolveComponent("van-radio");
      const _component_van_cell_group = _resolveComponent("van-cell-group");
      const _component_van_radio_group = _resolveComponent("van-radio-group");
      const _component_van_popup = _resolveComponent("van-popup");
      const _component_theme_config = _resolveComponent("theme-config");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_cell, {
        title: "支付银行卡",
        "title-style": "flex: none;",
        center: true,
        "is-link": true,
        onClick: _cache[0] || (_cache[0] = () => !disableForm.value && toSelectBank())
      }, {
        value: _withCtx(() => [!value.value ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(disableForm.value ? '' : bankCardList.value.length ? '请选择支付银行卡' : '添加银行卡'), 1)) : (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(selectBankCardInfo.value.bankName), 1), _createElementVNode("div", _hoisted_4, _toDisplayString(renderCardInfoText(selectBankCardInfo.value)), 1)]))]),
        _: 1
      }), (_openBlock(), _createBlock(_Teleport, {
        to: "body"
      }, [_createVNode(_component_theme_config, null, {
        default: _withCtx(() => [_createVNode(_component_van_popup, {
          show: radioPop.show,
          "onUpdate:show": _cache[2] || (_cache[2] = $event => radioPop.show = $event),
          round: "",
          position: "bottom",
          closeable: "",
          "close-on-popstate": true
        }, {
          default: _withCtx(() => [_cache[5] || (_cache[5] = _createElementVNode("div", {
            class: "fast-cards-pay-title"
          }, "请选择银行卡", -1)), _createElementVNode("div", _hoisted_5, [_createVNode(_component_van_radio_group, {
            modelValue: value.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => value.value = $event)
          }, {
            default: _withCtx(() => [_createVNode(_component_van_cell_group, null, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(bankCardList.value, card => {
                return _openBlock(), _createBlock(_component_van_cell, {
                  title: card.bankName,
                  clickable: "",
                  onClick: () => {
                    value.value = card.id;
                    radioPop.show = false;
                  }
                }, {
                  label: _withCtx(() => [_createVNode(_component_van_button, {
                    size: "mini",
                    style: {
                      "margin-right": ".6em",
                      "vertical-align": "middle"
                    },
                    onClick: _withModifiers($event => del(card.id), ["stop"])
                  }, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [_createTextVNode("解除绑定 ")])),
                    _: 2
                  }, 1032, ["onClick"]), _createElementVNode("span", null, _toDisplayString(renderCardInfoText(card)), 1)]),
                  "right-icon": _withCtx(() => [_createVNode(_component_van_radio, {
                    name: card.id
                  }, null, 8, ["name"])]),
                  _: 2
                }, 1032, ["title", "onClick"]);
              }), 256))]),
              _: 1
            })]),
            _: 1
          }, 8, ["modelValue"])]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_van_button, {
            block: "",
            round: "",
            type: "primary",
            loading: __props.loading,
            disabled: disableForm.value,
            onClick: toAddBankCard
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode(" 添加银行卡 ")])),
            _: 1
          }, 8, ["loading", "disabled"])])]),
          _: 1
        }, 8, ["show"])]),
        _: 1
      })]))], 64);
    };
  }
};