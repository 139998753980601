import _objectSpread from "/usr/local/src/wholesale-shop-h5/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { unref as _unref, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, isRef as _isRef, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  style: {
    "margin": "20px"
  }
};
const _hoisted_2 = {
  key: 0
};
import paginationSetup from '@/components/setup/paginationSetup';
import { ref, watch } from 'vue';
export default {
  __name: 'PagingList',
  props: {
    immediateCheck: {
      type: Boolean,
      default: () => true
    },
    getList: {
      type: Function,
      default: () => ({})
    },
    pageSize: {
      type: [Number, String],
      default: () => 20
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['update:dataList', 'update:pagination', 'update:loading', 'refresh'],
  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    let params = {};
    const $props = __props;
    const $emit = __emit;
    const pullRefreshStatus = ref('');
    const {
      dataList,
      pagination,
      loading,
      finished,
      refreshing,
      onLoad,
      onRefresh: _onRefresh
    } = paginationSetup(_objectSpread({
      getList(pageParams) {
        return $props.getList(_objectSpread(_objectSpread({}, pageParams), params));
      },
      pageSize: $props.pageSize
    }, $props.options));
    function onRefresh() {
      $emit('refresh');
      return _onRefresh();
    }
    watch(() => [dataList, pagination], ([list, paginationOpts]) => {
      $emit('update:dataList', list.value);
      $emit('update:pagination', _objectSpread({}, paginationOpts));
    }, {
      immediate: true,
      deep: true
    });
    watch(() => [loading], ([loading]) => {
      $emit('update:loading', loading.value);
    }, {
      immediate: true,
      deep: true
    });
    function onPullRefreshChange({
      status
    }) {
      pullRefreshStatus.value = status;
    }
    __expose({
      setParams(param) {
        param = param || {};
        params = _objectSpread({}, param);
      },
      dataList,
      pagination,
      loading,
      finished,
      refreshing,
      onLoad,
      onRefresh
    });
    return (_ctx, _cache) => {
      const _component_van_empty = _resolveComponent("van-empty");
      const _component_van_list = _resolveComponent("van-list");
      const _component_van_pull_refresh = _resolveComponent("van-pull-refresh");
      return _openBlock(), _createBlock(_component_van_pull_refresh, {
        modelValue: _unref(refreshing),
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _isRef(refreshing) ? refreshing.value = $event : null),
        class: _normalizeClass(pullRefreshStatus.value),
        disabled: _unref(loading),
        onRefresh: onRefresh,
        onChange: onPullRefreshChange
      }, {
        default: _withCtx(() => [_createVNode(_component_van_list, {
          loading: _unref(loading),
          "onUpdate:loading": _cache[0] || (_cache[0] = $event => _isRef(loading) ? loading.value = $event : null),
          finished: _unref(finished),
          "immediate-check": __props.immediateCheck,
          onLoad: _unref(onLoad)
        }, {
          finished: _withCtx(() => [_renderSlot(_ctx.$slots, "finished", {
            dataList: _unref(dataList),
            loading: _unref(loading)
          }, () => [_unref(dataList).length && !_unref(loading) ? (_openBlock(), _createElementBlock("div", _hoisted_2, "没有更多了")) : _createCommentVNode("", true), !_unref(dataList).length && !_unref(loading) ? (_openBlock(), _createBlock(_component_van_empty, {
            key: 1,
            description: "暂无相关内容"
          })) : _createCommentVNode("", true)])]),
          default: _withCtx(() => [_renderSlot(_ctx.$slots, "default", {
            dataList: _unref(dataList)
          }, () => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(dataList), data => {
            return _openBlock(), _createElementBlock("p", _hoisted_1, " Data: " + _toDisplayString(data), 1);
          }), 256))])]),
          _: 3
        }, 8, ["loading", "finished", "immediate-check", "onLoad"])]),
        _: 3
      }, 8, ["modelValue", "class", "disabled"]);
    };
  }
};