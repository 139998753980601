import _objectSpread from "/usr/local/src/wholesale-shop-h5/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, unref as _unref, Fragment as _Fragment, normalizeClass as _normalizeClass, renderSlot as _renderSlot, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "sku-header-goods-info-wrap"
};
const _hoisted_2 = {
  key: 0,
  class: "sku-select-info-text"
};
const _hoisted_3 = {
  key: 1,
  class: "sku-select-info-text"
};
const _hoisted_4 = {
  class: "sku-group-name-wrap"
};
const _hoisted_5 = {
  class: "sku-val-item-wrap"
};
const _hoisted_6 = ["onClick"];
const _hoisted_7 = {
  class: "count-select-wrap"
};
const _hoisted_8 = {
  class: "sku-selector-bottom-bar"
};
import { computed, reactive, ref } from 'vue';
import { showToast, showLoadingToast, closeToast } from 'vant';
export default {
  __name: 'SkuSelector',
  props: {
    show: {
      type: Boolean,
      default: () => false
    },
    zIndex: {
      type: [Number, String],
      default: () => 2000
    },
    options: {
      type: Object,
      default: () => ({})
    },
    initValue: {
      type: Array,
      default: () => []
    },
    checked: {
      type: Array,
      default: []
    },
    count: {
      type: [Number, String],
      default: 1
    }
  },
  emits: ['update:show', 'update:checked', 'update:count', 'confirm'],
  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    const defaultOpts = () => ({
      skuIdKey: 'id',
      // 组id
      skuNameKey: 'specGroupName',
      skuListKey: 'goodsSpecList',
      skuValueIdKey: 'id',
      // 规格id
      skuValueNameKey: 'specValue',
      skuImgKey: 'specImage',
      checkedKey: 'checked',
      defaultImg: 'logo.jpg',
      price: 0,
      getList: [],
      // [] || fn
      count: 1,
      // Num || fn
      checked: [],
      onConfirm: null,
      // fn || null
      btnText: '确认'
    });
    let options = defaultOpts();
    const $props = __props;
    const $emit = __emit;
    const price = ref('');
    const dataList = ref([]);
    const skuCount = ref(1);
    const max = ref(Infinity);
    const selectorShow = computed({
      get() {
        const {
          show
        } = $props;
        if (show) {
          init();
        }
        return show;
      },
      set(val) {
        $emit('update:show', val);
      }
    });
    const skuChecked = computed(() => {
      return [[], ...dataList.value].reduce((arr, e) => {
        let check = e[options.checkedKey];
        check = check !== null ? [check] : [];
        return [...arr, ...check];
      });
    });
    const skuCheckedText = computed(() => skuChecked.value.map(e => e[options.skuValueNameKey]).join('、'));
    const firstSkuGroupImg = computed(() => {
      const {
        skuImgKey,
        defaultImg
      } = options;
      const [first] = skuChecked.value;
      if (!first) return defaultImg;
      return first[skuImgKey] ? first[skuImgKey] : defaultImg;
    });
    async function init() {
      const {
        options: propOpts
      } = $props;
      options = _objectSpread(_objectSpread({}, defaultOpts()), propOpts);

      // init price
      price.value = options.price;

      // init skuCount
      skuCount.value = options.count;

      // init max
      max.value = options.max;

      // init sku list
      const getList = options.skuList;
      let list = [];
      if (Array.isArray(getList)) {
        list = getList;
      } else if (typeof getList === 'function') {
        dataList.value = [];
        showLoadingToast({
          duration: 0,
          forbidClick: true
        });
        await getList().then(res => {
          closeToast();
          list = Array.isArray(res) ? res : [];
        });
      }
      list.forEach((e, i) => {
        var _options$checked;
        const checkedData = (_options$checked = options.checked) === null || _options$checked === void 0 ? void 0 : _options$checked[i];
        const initId = checkedData === null || checkedData === void 0 ? void 0 : checkedData[options.skuValueIdKey];
        const [initData] = e[options.skuListKey].filter(se => {
          const seId = se === null || se === void 0 ? void 0 : se[options.skuValueIdKey];
          return initId && seId && seId === initId;
        });
        e[options.checkedKey] = initData || null;
      });
      dataList.value = list;
    }
    function selectSku(item, group) {
      const {
        skuValueIdKey,
        checkedKey
      } = options;
      const isChecked = group[checkedKey] && group[checkedKey][skuValueIdKey] === item[skuValueIdKey];
      group['checked'] = isChecked ? null : item;
      $emit('update:checked', JSON.parse(JSON.stringify(skuChecked.value)));
    }
    let __time = null;
    function onConfirm() {
      const {
        skuNameKey,
        checkedKey,
        skuValueIdKey,
        onConfirm
      } = options;
      let arr = [];
      for (const sku of dataList.value) if (!sku[checkedKey]) arr.push(sku[skuNameKey]);
      if (arr.length) {
        showToast({
          position: 'bottom',
          message: `请选择规格：${arr.join('、')}`
        });
        return;
      }
      if (__time && Date.now() - __time < 1000) return;
      __time = Date.now();
      const confirmData = {
        sku: skuChecked.value.map(e => e[skuValueIdKey]),
        skuObj: JSON.parse(JSON.stringify(skuChecked.value)),
        count: skuCount.value
      };
      $emit('confirm', confirmData);
      typeof onConfirm === 'function' && onConfirm(confirmData);
      selectorShow.value = false;
      return confirmData;
    }
    __expose({
      onConfirm
    });
    return (_ctx, _cache) => {
      const _component_van_image = _resolveComponent("van-image");
      const _component_beauty_price = _resolveComponent("beauty-price");
      const _component_van_stepper = _resolveComponent("van-stepper");
      const _component_van_button = _resolveComponent("van-button");
      const _component_van_popup = _resolveComponent("van-popup");
      return _openBlock(), _createBlock(_component_van_popup, {
        show: selectorShow.value,
        "onUpdate:show": _cache[2] || (_cache[2] = $event => selectorShow.value = $event),
        position: "bottom",
        closeable: "",
        round: "",
        duration: "0.2",
        "z-index": __props.zIndex
      }, {
        default: _withCtx(() => [_createElementVNode("header", null, [_createVNode(_component_van_image, {
          class: "sku-selector-header-img",
          fit: "fill",
          src: firstSkuGroupImg.value
        }, null, 8, ["src"]), _createElementVNode("div", _hoisted_1, [_createVNode(_component_beauty_price, {
          value: price.value,
          "font-size": [26, 19]
        }, null, 8, ["value"]), dataList.value.length ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("span", null, _toDisplayString(skuChecked.value.length ? '已选' : '请选择商品规格'), 1), _createTextVNode(_toDisplayString(skuCheckedText.value), 1)])) : (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[3] || (_cache[3] = [_createElementVNode("span", null, "请选择数量", -1)])))])]), _createElementVNode("section", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dataList.value, group => {
          return _openBlock(), _createElementBlock(_Fragment, {
            key: group[_unref(options).skuIdKey]
          }, [_createElementVNode("div", _hoisted_4, _toDisplayString(group[_unref(options).skuNameKey]), 1), _createElementVNode("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group[_unref(options).skuListKey], item => {
            return _openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["sku-val-item", {
                'sku-active': group[_unref(options).checkedKey] && group[_unref(options).checkedKey][_unref(options).skuValueIdKey] === item[_unref(options).skuValueIdKey]
              }]),
              key: item[_unref(options).skuValueIdKey],
              onClick: $event => selectSku(item, group)
            }, _toDisplayString(item[_unref(options).skuValueNameKey]), 11, _hoisted_6);
          }), 128))])], 64);
        }), 128)), _createElementVNode("div", _hoisted_7, [_cache[4] || (_cache[4] = _createTextVNode(" 数量： ")), _createVNode(_component_van_stepper, {
          "long-press": false,
          "disable-input": true,
          min: 1,
          max: max.value,
          modelValue: skuCount.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => skuCount.value = $event),
          onChange: _cache[1] || (_cache[1] = $event => $emit('update:count', skuCount.value))
        }, null, 8, ["max", "modelValue"])]), _createElementVNode("div", _hoisted_8, [_renderSlot(_ctx.$slots, "btnBar", {}, () => [_createVNode(_component_van_button, {
          block: "",
          type: "warning",
          onClick: onConfirm
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(options).btnText), 1)]),
          _: 1
        })])])])]),
        _: 3
      }, 8, ["show", "z-index"]);
    };
  }
};